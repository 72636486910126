<template>
  <v-simple-table class="members-table rounded-0">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="px-4 text-body gray-100--text" style="width: 200px">
            User
          </th>
          <th class="px-4 text-body gray-100--text" style="width: 72px">
            <div class="d-flex align-center">
              Space Role
              <v-tooltip top color="rgba(47, 49, 53, 0.7)">
                <template #activator="{ attrs, on }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="cursor-pointer d-flex align-center ml-2"
                  >
                    <IconHelpCircle width="16" />
                  </span>
                </template>
                <div class="text-captions-1">
                  Admins can edit team<br />composition in space<br />settings
                </div>
              </v-tooltip>
            </div>
          </th>
          <th class="px-4 text-body gray-100--text" style="width: 72px">
            <div class="d-flex align-center">
              Project Role
              <v-tooltip top color="rgba(47, 49, 53, 0.7)">
                <template #activator="{ attrs, on }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="cursor-pointer d-flex align-center ml-2"
                  >
                    <IconHelpCircle width="16" />
                  </span>
                </template>
                <div class="text-captions-1">
                  Assigned Project roles <br />will be added to all <br />newly
                  created projects
                </div>
              </v-tooltip>
            </div>
          </th>
          <th class="px-4 text-body gray-100--text" style="width: 72px">
            Status
          </th>
          <th
            class="px-4 text-body gray-100--text text-no-wrap"
            style="width: 1%"
          >
            Last Active
          </th>
          <th class="px-4 text-body gray-100--text" style="width: 1%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(member, idx) in members" :key="idx">
          <td class="px-4">
            <div
              class="d-flex align-center mr-auto overflow-hidden"
              style="max-width: 410px"
            >
              <UiAvatar
                :text="fullName(member.user)"
                size="32"
                text-class="text-body-lg gray-80--text"
                class="mr-2"
              />
              <div class="text-captions-1 gray-80--text">
                <div class="font-weight-semi-bold">
                  {{ fullName(member.user) }}
                </div>
                <div>{{ member.user.email }}</div>
              </div>
            </div>
          </td>
          <td class="px-4">
            {{ $config.space.userRoleLabels[member.user_role.name] }}
          </td>
          <td class="px-4 py-2">
            <span>{{ userProjectRole(member.project_roles) }}</span>
          </td>
          <td class="px-4">
            <div style="max-width: 72px">
              <UiProjectStatus block :id="member.status" statusType="space" />
            </div>
          </td>
          <td class="px-4">
            <div class="d-flex text-captions-1 text-no-wrap gray-60--text">
              {{ createdAt(member.created_at) }}
            </div>
          </td>
          <td class="px-4">
            <UiMenu
              left
              offset-y
              v-if="
                space.currentSpaceUser.user.id !== member.user.id && canEdit
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <UiBtn v-bind="attrs" v-on="on" icon>
                  <IconDotsH width="18" class="gray-60--text" />
                </UiBtn>
              </template>

              <v-list nav dense color="gray-10" min-width="98">
                <v-list-item @click.prevent="$emit('edit', member)">
                  <v-list-item-title class="text-captions-1">
                    <IconPen width="15" class="mr-2 gray-60--text" />
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click.prevent="$emit('delete', member)">
                  <v-list-item-title class="text-captions-1">
                    <IconDelete width="15" class="mr-2 gray-60--text" />
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </UiMenu>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import UiAvatar from "@/components/UI/UiAvatar";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import { format } from "date-fns";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import IconDotsH from "@/components/icons/IconDotsH";
import IconPen from "@/components/icons/IconPen";
import IconDelete from "@/components/icons/IconDelete";
import { mapGetters } from "vuex";
import IconHelpCircle from "@/components/icons/IconHelpCircle.vue";

export default {
  name: "MembersTable",
  components: {
    IconHelpCircle,
    IconDelete,
    IconPen,
    IconDotsH,
    UiBtn,
    UiMenu,
    UiProjectStatus,
    UiAvatar,
  },
  props: {
    space: {
      type: Object,
      required: true,
    },
    members: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["getRolesList"]),
    fullName() {
      return (user) => {
        const firstName = user.first_name || "";
        const lastName = user.last_name || "";
        return `${firstName} ${lastName}`.trim();
      };
    },
    canEdit() {
      return (
        this.space.currentSpaceUser.user_role.name ===
        this.$config.space.userRole.spaceAdmin
      );
    },
    createdAt() {
      return (date) => {
        return format(new Date(date), "HH:mm, P");
      };
    },
    userProjectRole() {
      return (projectRoles) => {
        return projectRoles
          .map((role) => {
            return role.display_name;
          })
          .join(", ");
      };
    },
  },
  methods: {
    format,
  },
};
</script>

<style scoped lang="scss">
.members-table {
  :deep(table) {
    border-spacing: 0 10px;

    thead th {
      border-bottom: 1px solid var(--v-gray-30-base) !important;
    }

    td {
      border-top: 1px solid var(--v-gray-30-base) !important;
      border-bottom: 1px solid var(--v-gray-30-base) !important;
      height: 64px !important;

      &:first-child {
        border-left: 1px solid var(--v-gray-30-base) !important;
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-right: 1px solid var(--v-gray-30-base) !important;
        border-radius: 0 5px 5px 0;
      }
    }
  }
}
</style>
